@import './mixins';
@import './variables';

:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
}
@include defineCustomFontFamily('open-sans', 700);

:root {
  --body-background-color: #fff;
  --max-content-width: 1500px;
  --content-margin: 20px;
  --base-font-size: 16px;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);
  font-size: var(--base-font-size);
}

a {
  color: var(--primary-color);
}


button[sitemule-button]:not([bold]) {
  font-weight: normal;
}

textarea[appautoheight] {
  color: var(--text-color);
  border: 1px dashed var(--text-tint-75);
  border-radius: 13px;
  padding: 15px 20px;
  font-weight: var(--font-weight, normal);
  font-family: var(--font-family);
  font-size: var(--font-size, 16px);
  line-height: var(--line-height, 24px);;
  transition: border-color .15s linear;
  display: block;
  width: 100%;

  &[disabled] {
    background-color: var(--text-tint-98);
  }

  &[disabled],
  &::placeholder {
    color: var(--text-tint-75);
  }

  &:focus-visible {
    outline: none;
    border-color: var(--text-color);
  }
}

.p-input-field {
  label {
    display: block;
    font-weight: bold;
    color: var(--text-tint-25);
    line-height: 30px;
    font-size: 14px;
  }

  textarea,
  input {
    width: 100%;
    display: block;
  }

  p-dropdown .p-dropdown {
    display: flex;
  }

  .error-text {
    display: block;
    margin-top: 2px;
  }

  + .p-input-field {
    margin-top: 22px;
  }
}


.toast-link {
  color: #fff;
}

.primeng-tooltip {

  &.p-tooltip {

    .p-tooltip-text {
      font-size: 14px;
      line-height: 17px;
      padding: 20px;
    }
  }

  &.p-tooltip-bottom {
    padding: 7px 0;

    .p-tooltip-arrow {
      width: 10px;
      top: 3px;
      height: 4px;
    }
  }
}

.p-component.p-dialog {
  max-width: 90%;
}
