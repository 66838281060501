$bp-size-xs: 400px !default; // portrait mobile
$bp-size-sm: 768px !default; // Landscape mobile
$bp-size-md: 992px !default; // Tablet
$bp-size-lg: 1200px !default; // Desktop
$bp-size-xl: 99999px !default; // XL Desktop

$bp-size-less-xs: #{$bp-size-xs - 1};
$bp-size-less-sm: #{$bp-size-sm - 1};
$bp-size-less-md: #{$bp-size-md - 1};
$bp-size-less-lg: #{$bp-size-lg - 1};
$bp-size-less-xl: #{$bp-size-xl - 1};

$size-xs: "(min-width: 0)"; // All screen size (Do not need use it)
$size-sm: "(min-width: #{$bp-size-xs})"; // Portrait and above
$size-md: "(min-width: #{$bp-size-sm})"; // Landscape and above
$size-lg: "(min-width: #{$bp-size-md})"; // Tablet and above
$size-xl: "(min-width: #{$bp-size-lg})"; // Desktop and above

$size-xs-and-below: "(max-width: #{$bp-size-less-xs})"; // Portrait and below
$size-sm-and-below: "(max-width: #{$bp-size-less-sm})"; // Landscape and below
$size-md-and-below: "(max-width: #{$bp-size-less-md})"; // Tablet and below
$size-lg-and-below: "(max-width: #{$bp-size-less-lg})"; // Desktop and below
$size-xl-and-below: "(max-width: #{$bp-size-less-xl})"; // XL Desktop and below (Mostly unusable)

$size-only-xs: "#{$size-xs-and-below}"; // Portrait mobile only
$size-only-sm: "(min-width: #{$bp-size-xs}) and #{$size-sm-and-below}"; // Landscape mobile only
$size-only-md: "(min-width: #{$bp-size-sm}) and #{$size-md-and-below}"; // Tablet only
$size-only-lg: "(min-width: #{$bp-size-md}) and #{$size-lg-and-below}"; // Desktop only
$size-only-xl: "(min-width: #{$bp-size-lg}) and #{$size-xl-and-below}"; // XL Desktop only

$size-not-xs: "(min-width: #{$bp-size-xs})"; // Not Portrait
$size-not-sm: "(min-width: #{$bp-size-sm}, max-width: #{$bp-size-less-xs})"; // Not Landscape
$size-not-md: "(min-width: #{$bp-size-md}, max-width: #{$bp-size-less-sm})"; // Not Tablet
$size-not-lg: "(min-width: #{$bp-size-lg}, max-width: #{$bp-size-less-md})"; // Not Desktop
$size-not-xl: "(min-width: #{$bp-size-xl}, max-width: #{$bp-size-less-lg})"; // Not XL Desktop

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage * 1%);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage * 1%);
}

@mixin defineBaseColorVariables($colors) {
  @each $name, $color in $colors {
    --#{$name}-color: #{$color};
  }
}

@mixin defineTintColorVariables($colors) {
  @each $name, $color in $colors {
    $i: 1;
    @while $i < 100 {
      --#{$name}-tint-#{$i}: #{tint($color, $i)};
      $i: $i + 1;
    }
  }
}

@mixin defineShadeColorVariables($colors) {
  @each $name, $color in $colors {
    $i: 1;
    @while $i < 100 {
      --#{$name}-shade-#{$i}: #{shade($color, $i)};
      $i: $i + 1;
    }
  }
}

@mixin defineZIndexVariables {
  $indices: 0, 1, 10, 100, 1000, 10000, 100000, 1000000, 10000000;
  --layer-negative: -1;
  @for $i from 1 through length($indices) {
    $index: nth($indices, $i);
    --layer-#{$i}: #{$index};
  }
}
@mixin defineCustomFontFamily($font-name, $bold-font-weight) {
  @font-face {
    font-family: $font-name;
    src: url("/assets/fonts/#{$font-name}/#{$font-name}-regular.ttf");
  }
  @font-face {
    font-family: $font-name;
    font-weight: $bold-font-weight;
    src: url("/assets/fonts/#{$font-name}/#{$font-name}-bold.ttf");
  }
  @font-face {
    font-family: $font-name;
    src: url("/assets/fonts/#{$font-name}/#{$font-name}-bold-italic.ttf");
    font-weight: $bold-font-weight;
    font-style: italic;
  }
  @font-face {
    font-family: $font-name;
    src: url("/assets/fonts/#{$font-name}/#{$font-name}-italic.ttf");
    font-style: italic;
  }
  :root {
    --font-family: #{$font-name}, sans-serif;
    --bold-font-weight: #{$bold-font-weight};
  }
}

@mixin hover {
  &:focus {
    @content;
  }

  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
